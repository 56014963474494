import Swiper, { Navigation, Keyboard } from "Parent/js/modules/swiper/swiper";

Swiper.use([Navigation, Keyboard]);

class Sliders {
	constructor() {

        this.home_slider_el = document.querySelector(".events-slider");
		this.home_slider = null;

		if (this.home_slider_el) {
			this.runHomeSlider();
		}

		this.visit_slider_el = document.querySelector(".visit-slider");
		this.visit_slider = null;

		if (this.visit_slider_el) {
			this.runVisitSlider();
		}

	}

	runHomeSlider() {
		this.home_slider = new Swiper(this.home_slider_el, {
            loop: true,
            loopedSlides: 10,
            slidesPerView: "auto",
            spaceBetween: 30,
            navigation: {
				nextEl: ".events-slider .swiper-right",
                prevEl: ".events-slider .swiper-left"
			},

		});
	}

	runVisitSlider() {
		this.visit_slider = new Swiper(this.visit_slider_el, {
            loop: true,
            loopedSlides: 10,
            slidesPerView: "auto",
            spaceBetween: 10,
            navigation: {
				nextEl: ".visit-slider .swiper-right",
                prevEl: ".visit-slider .swiper-left"
			},

		});
	}
}

export default Sliders;
